import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dropdown from "../../common/Dropdown";

import {
  fetchDropdownData,
  handleSearchAction,
  fetchItemsDropdownOptions,
  setSelectedStoreDetails,
  displayResultsAction,
} from "../../../store/actions/austinReuseDirectory.actions";

const SearchComponent = (props) => {
  const { onSearchClick } = props;
  const [lookingToIndex, setLookingToIndex] = useState(0);
  const [itemsIndex, setItemsIndex] = useState(0);
  const [showErrMsg, setShowErrMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDropdownData());
  }, [dispatch]);

  const { dropdownData, resetFilter } = useSelector(
    (state) => state.arrReducer
  );
  const { lookingTo, typeOfItems } = dropdownData || {};

  useEffect(() => {
    if (resetFilter) {
      setLookingToIndex(0);
      setItemsIndex(0);
    }
  }, [resetFilter]);

  const handleSearchClick = () => {
    if (lookingTo[lookingToIndex].toLowerCase() === "select") {
      setShowErrMsg("Looking To");
      dispatch(displayResultsAction(false));
    } else if (typeOfItems[itemsIndex].toLowerCase() === "select") {
      setShowErrMsg("Type of Items");
      dispatch(displayResultsAction(false));
    } else {
      setShowErrMsg("");
      const requestParams = {
        lookingTo: lookingTo[lookingToIndex],
        typeOfItems: typeOfItems[itemsIndex],
      };
      dispatch(handleSearchAction(requestParams));
      dispatch(setSelectedStoreDetails({}));
      onSearchClick();
    }
  };

  return (
    <div className="search-container container">
      <div className="search-container__title">
        <p className="search-container__title-text">
          Find where to donate, resell, buy responsibly, rent, and repair items,
          while supporting our local reuse economy.
        </p>
      </div>
      <div className="note text-center mb-3">Note: Select from left to right.</div>
      {Object.keys(dropdownData).length > 0 && (
        <div className="d-flex search-container__main">
          <div className="search-container__main-section">
            <p className="search-container__main-title">Looking To</p>
            {(lookingTo || []).length > 0 && (
              <Dropdown
                list={lookingTo}
                onselect={(index) => {
                  setLookingToIndex(index);
                  setShowErrMsg("");
                  setItemsIndex(0);
                  dispatch(fetchItemsDropdownOptions(lookingTo[index]));
                }}
                defaultSelectedIndex={lookingToIndex}
                customClass="look-to-dropdown"
              />
            )}
          </div>
          <div className="search-container__main-section">
            <p className="search-container__main-title">Type of Items</p>
            {(typeOfItems || []).length > 0 && (
              <Dropdown
                list={typeOfItems}
                onselect={(index) => {
                  setItemsIndex(index);
                  setShowErrMsg("");
                }}
                defaultSelectedIndex={itemsIndex}
                customClass="items-dropdown"
              />
            )}
          </div>
          <button
            type="button"
            className="search-container__main-btn"
            onClick={handleSearchClick}
          >
            Search
          </button>
        </div>
      )}
      {showErrMsg && (
        <div className="d-flex justify-content-center mt-4 error-msg">
          {`Please select an option from dropdown`}&nbsp;
          <b>{showErrMsg}</b>
        </div>
      )}
    </div>
  );
};

SearchComponent.defaultProps = {};

SearchComponent.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
};

export default SearchComponent;